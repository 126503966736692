<template>
  <div class="changePassword">
    <div class="_title"
         v-if="title">修改密码</div>
    <div class="_content"
         :class="className">
      <v-card flat
              class="mt-4"
              min-height="350">
        <v-card-text class="pa-0">
          <v-form ref="form"
                  v-model="valid"
                  lazy-validation
                  :style="formStyle">
            <div class="d-flex justify-center align-center">
              <div class="label required mr-6"
                   style="width: 100px; text-align: end">
                原密码:
              </div>
              <v-text-field v-model="password.oldPasswd"
                            :rules="rules.oldPasswd"
                            type="password"
                            required />
            </div>
            <div class="d-flex justify-center align-center">
              <div class="label required mr-6"
                   style="width: 100px; text-align: end">
                新密码:
              </div>
              <v-text-field type="password"
                            v-model="password.newPasswd"
                            :rules="rules.newPasswd"
                            required />
            </div>
            <div class="d-flex justify-center align-center">
              <div class="label required mr-6"
                   style="width: 100px; text-align: end">
                确认密码:
              </div>
              <v-text-field type="password"
                            v-model="password.newPassword"
                            :rules="rules.newPassword"
                            required />
            </div>
            <v-btn :disabled="!valid"
                   style="min-width: 0 !important; width: 300px; margin: 0 auto;"
                   color="success"
                   block
                   class="mt-4"
                   @click="passwordAction">
              确定
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      valid: true,

      password: {
        oldPasswd: '',
        newPasswd: '',
        newPassword: '',
      },
      rules: {
        oldPasswd: [(v) => !!v || '请输入原密码'],
        newPasswd: [
          (v) => !!v || '请输入新密码',
          (v) => v.length >= 6 || '密码不能小于6位',
        ],
        newPassword: [
          (v) => !!v || '请输入确认密码',
          (v) => this.password.newPasswd == v || '2次密码不一致',
        ],
      },
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    formStyle () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'width: 100%; margin: auto'
        case 'sm':
          return 'width: 50%; margin: auto'
        case 'md':
          return 'width: 60%; margin: auto'
        case 'lg':
          return 'width: 40%; margin: auto'
        case 'xl':
          return 'width: 40%; margin: auto'
      }
    },
  },
  props: {
    title: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: 'tab-item',
    },
  },
  methods: {
    FedLogOut () {
      this.$store
        .dispatch('FedLogOut')
        .then(() => {
          this.$message.tips('退出成功')
          this.$router.push({ path: this.redirect || '/' }).catch(() => { })
        })
        .catch(() => {
          this.getCode()
        })
    },
    clear () {
      // this.$refs.form.reset()
      this.password.oldPasswd = ''
      this.password.newPasswd = ''
      this.password.newPassword = ''
    },
    passwordAction () {
      let data = {}
      data.oldPasswd = this.$md5(this.password.oldPasswd)
      data.newPasswd = this.$md5(this.password.newPasswd)
      data.newPassword = this.$md5(this.password.newPassword)
      this.$request({
        url: '/user/update/passwd',
        method: 'post',
        data: data,
      }).then(() => {
        this.$message.tips('修改成功，请重新登陆')
        this.FedLogOut()
      })
    },
  },
}
</script>

<style lang="less" scoped>
.changePassword {
  padding: 0 10px;
  padding-bottom: 150px;
  .item-boeder {
    border: 1px solid #c4d5e0;
  }
  .required {
    position: relative;
    width: 80px;
    text-align: end;
    &::before {
      content: "*";
      // position: absolute;
      left: 10%;
      top: 30%;
      font-size: 20px;
      color: red;
    }
  }
  ._content {
    .v-card__text {
      font-size: 15px !important;
      line-height: 25px;
    }
  }
  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
}
</style>
